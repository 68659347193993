import React, { useState, useCallback, useRef, useEffect } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  FaMotorcycle,
  FaMapMarkerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaMoneyBillWaveAlt,
  FaCreditCard,
  FaSearchLocation,
} from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import Header from "../../components/PageHeader";
import { useDelivery } from "../../hooks/delivery-payment";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Input from "../../components/Input";
import InputMask from "../../components/InputMask";
import formatValue from "../../utils/formatValue";
import { useCart } from "../../hooks/cart";
// import CurrencyInput from "../../components/InputMoney";
import InputCurrency from '../../components/InputCurrency';
import removeEspecialsCharacters from "../../utils/removeSpecialsCharacters";
import api from "../../services/api";
import CheckboxInput from "../../components/Check";
import getValidationErrors from "../../utils/getValidationErros";
import Select from "../../components/Select";
import { cardsOptions } from "../../assets/data/cards";
import IUseParams from "../../interfaces/IUseParams";
import { useConfig } from '../../hooks/config';
import "./styles.css";

interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

const DeliveryPayment: React.FC = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [tabPaymentSelected, setPaymentTabSelected] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeValue, setChangeValue] = useState(0.00);
  const [checkedChange, setCheckedChange] = useState(false);
  const { include, delivery, payment } = useDelivery();
  const { cart } = useCart();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [indexCardSelected, setIndexCardSelected] = useState(() => {
    return cardsOptions.findIndex((card) => card.value === payment.card_flag);
  });

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { company } = useParams<IUseParams>();
  const { load, config } = useConfig();

  useEffect(() => {
    window.scrollTo(0, 0);
    load(company);
    console.log('useEfffect load');
  }, [company, load]);

  const handleValueChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!checkedChange) {
        const n = Number(event.currentTarget.value.replace('R$', '').replace(',', ''));
        setChangeValue(n);
      }
    },
    [checkedChange]
  );

  useEffect(() => {    
    setChangeValue(payment.change_value * 100);

    config.method_delivery === 'BOTH' ?
      setTabSelected(delivery.type_delivery) :
      setTabSelected(config.method_delivery === 'ONLY_DELIVERY' ? 0 : 1);

    console.log('delivery.type_delivery', delivery.type_delivery);
    
    setPaymentTabSelected(payment.method_payment);
    payment.non_change && setCheckedChange(payment.non_change);

    console.log('useEfffect 1');
  }, [
    config.method_delivery,
    delivery.type_delivery,
    payment.change_value,
    payment.method_payment,
    payment.non_change,
    payment.card_flag,
  ]);

  useEffect(() => {
    formRef.current?.setData({
      celphone: delivery.celphone,
      name: delivery.name,

      cep: delivery.cep,
      address: delivery.address,
      number: delivery.number,
      complement: delivery.complement,
      neighborhood: delivery.neighborhood,
      reference: delivery.reference,

      change_value: payment.change_value,
      nonchange: ["non-change"],
    });
    console.log('useEfffect 2');
  }, [
    delivery.address,
    delivery.celphone,
    delivery.cep,
    delivery.complement,
    delivery.name,
    delivery.neighborhood,
    delivery.number,
    delivery.reference,
    payment.change_value,
  ]);

  const handleChangeCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedChange(event.currentTarget.checked);
      if (event.currentTarget.checked) {
        setChangeValue(0);
      }
    },
    []
  );

  const handlefindCEP = useCallback((cep: string) => {
    api
      .get(`https://viacep.com.br/ws/${removeEspecialsCharacters(cep)}/json/`)
      .then((response: { data: { logradouro: any; complemento: any; bairro: any; }; }) => {
        formRef.current?.setFieldValue("address", response.data.logradouro);
        formRef.current?.setFieldValue("complement", response.data.complemento);
        formRef.current?.setFieldValue("neighborhood", response.data.bairro);
      });
  }, []);

  const handleSetDataFormToObjectsContext = useCallback(async () => {
    console.log(delivery);
    delivery.name = formRef.current?.getFieldValue("name");
    delivery.celphone = formRef.current?.getFieldValue("celphone");

    delivery.cep = formRef.current?.getFieldValue("cep");
    delivery.address = formRef.current?.getFieldValue("address");
    delivery.number = formRef.current?.getFieldValue("number");
    delivery.complement = formRef.current?.getFieldValue("complement");
    delivery.neighborhood = formRef.current?.getFieldValue("neighborhood");
    delivery.reference = formRef.current?.getFieldValue("reference");

    delivery.type_delivery = tabSelected;

    payment.method_payment = tabPaymentSelected;
    payment.change_value = formRef.current?.getFieldValue("change_value");

    if (delivery.type_delivery === 0) {
      if (tabPaymentSelected === 0) {
        payment.non_change = formRef.current?.getFieldValue("nonchange")[0];
        payment.change_value =
          formRef.current
            ?.getFieldValue("change_value")
            .replace(/[^0-9]/g, "") / 100;
      } else {
        payment.card_flag = formRef.current?.getFieldValue("cards");
      }
    } else {
      payment.non_change = false;
    }

    await include(delivery, payment);
  }, [delivery, include, payment, tabPaymentSelected, tabSelected]);

  const handleValidateForm = useCallback(
    async (data) => {

      if (!cart) {
        history.push(`/${company}`);
        return null;
      }

      try {
        formRef.current?.setErrors({});

        let schema = Yup.object();

        schema = Yup.object().shape({
          type_delivery: Yup.number().default(() => tabSelected),

          celphone: Yup.string().required()
            .transform(function (value, originalValue) {
              return value.replace(/[^0-9]/g, "").length < 11
                ? ""
                : value
            }),

          name: Yup.string().required("Nome obrigatório").max(50),

          cep: Yup.string().when("type_delivery", {
            is: 0,
            then: Yup.string().required("CEP obrigatório"),
            otherwise: Yup.string().notRequired(),
          }),

          address: Yup.string().when("type_delivery", {
            is: 0,
            then: Yup.string().required("Endereço obrigatório"),
            otherwise: Yup.string().notRequired(),
          }).max(50),

          number: Yup.string().when("type_delivery", {
            is: 0,
            then: Yup.string().required("Numero obrigatório"),
            otherwise: Yup.string().notRequired(),
          }).max(12),

          neighborhood: Yup.string().when("type_delivery", {
            is: 0,
            then: Yup.string().required("Bairro obrigatório"),
            otherwise: Yup.string().notRequired(),
          }).max(30),

          complement: Yup.string().max(50),

          reference: Yup.string().max(30),

          payment_method: Yup.number().default(() => tabPaymentSelected),

          non_change: Yup.boolean().default(() => checkedChange),

          change_value: Yup.string().when(
            ["non_change", "payment_method", "type_delivery"],
            {
              is: (non_change, payment_method, type_delivery) =>
                !non_change && payment_method === 0 && type_delivery === 0,
              then: Yup.string()
                .transform(function (value, originalValue) {
                  return value === "R$ 0,00"
                    ? ""
                    : (value.replace(/[^0-9]/g, "") / 100 < cart.total) || (value.replace(/[^0-9]/g, "") / 100 > 999)
                      ? ""
                      : value;
                })
                .required("Digite um valor válido"),
              otherwise: Yup.string().notRequired(),
            }
          ),

          cards: Yup.string().when("payment_method", {
            is: 1,
            then: Yup.string().required(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return false;
        }
      }
      return true;
    },
    [checkedChange, tabPaymentSelected, tabSelected, cart, company, history]
  );

  const handleSubmit = useCallback(
    async (data) => {
      if (!(await handleValidateForm(data))) {
        return;
      }

      await handleSetDataFormToObjectsContext();

      history.push(`/${company}/order-resume`);
      //
    },
    [handleSetDataFormToObjectsContext, handleValidateForm, history, company]
  );

  const checkboxOptions: CheckboxOption[] = [
    { id: "non-change", value: "true", label: "" },
  ];

  if (!cart) {
    history.push(`/${company}`);
    return null;
  }

  if (!config){
    return null;
  }

  return (
    <div className="page-delpay">
      <Header />

      <div className="page-delpay-container">

        <Link to={`/${company}/`} id="back-link">
          <FaChevronLeft />
          Continuar comprando
        </Link>

        <div className="page-delpay-form">
          <h3>Informações para entrega</h3>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="page-delpay-contact-container">
              <InputMask
                type="text"
                mask="(99)99999-9999"
                name="celphone"
                id="celphone"
                // maxLength={15}
                placeholder="Celular"
                autoFocus
                containerClassName="input-container input-container-sm"
              />
              <Input
                type="text"
                name="name"
                maxLength={50}
                placeholder="Nome"
                containerClassName="input-container"
              />
            </div>

            {
              config.method_delivery !== 'BOTH' &&
              <div className="page-delpay-method-delivery">
                <h5>Este estabelecimento trabalha somente com
                  {config.method_delivery === 'ONLY_DELIVERY' ? ' ENTREGAS.' : ' RETIRADA NO LOCAL.'}</h5>
              </div>
            }

            <div className="page-delpay-payment-withdraw-container">
              <Tabs                
                defaultIndex={
                  delivery.type_delivery === 1 && 2 ? 
                  delivery.type_delivery :
                  ['BOTH', 'ONLY_DELIVERY'].indexOf(config.method_delivery) > -1 ? 0 : 1}
                disabledTabClassName={'page-delpay-tablist-tab tab-non-selected'}
                onSelect={(index) => { setTabSelected(index) } }
              >
                <TabList className="page-delpay-tablist">

                  <Tab
                    disabled={['BOTH', 'ONLY_DELIVERY'].indexOf(config.method_delivery) === -1}
                    // 
                    className={
                      tabSelected === 0
                        ? "page-delpay-tablist-tab tab-selected"
                        : "page-delpay-tablist-tab tab-non-selected"
                    }
                  >
                    <FaMotorcycle />
                    Entrega
                  </Tab>

                  <Tab
                    disabled={['BOTH', 'ONLY_WITHDRAW'].indexOf(config.method_delivery) === -1}
                    className={
                      tabSelected === 1
                        ? "page-delpay-tablist-tab tab-selected"
                        : "page-delpay-tablist-tab tab-non-selected"
                    }
                  >
                    <FaMapMarkerAlt />
                    Retirar no local
                  </Tab>

                </TabList>

                <TabPanel>
                  <div className="delpay-content-address">
                    <div className="delpay-content-address-form-fields">
                      <div className="delpay-content-address-cep">
                        <InputMask
                          type="text"
                          mask="99.999-999"
                          name="cep"
                          id="cep"
                          placeholder="CEP"
                          containerClassName="input-container input-container-sm"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            handlefindCEP(formRef.current?.getFieldValue("cep"))
                          }
                        >
                          <FaSearchLocation />
                          Buscar CEP
                        </button>
                      </div>
                      <div className="delpay-content-address-form-row">
                        <Input
                          type="text"
                          name="address"
                          id="address"
                          maxLength={50}
                          placeholder="Endereço"
                          containerClassName="input-container"
                        />
                        <Input
                          type="text"
                          name="number"
                          id="number"
                          maxLength={12}
                          placeholder="Nº"
                          containerClassName="input-container input-container-sm"
                        />
                      </div>
                      <Input
                        containerClassName="input-container"
                        type="text"
                        name="complement"
                        id="complement"
                        maxLength={50}
                        placeholder="Complemento"
                      />
                      <div className="delpay-content-address-form-row">
                        <Input
                          type="text"
                          name="neighborhood"
                          id="neighborhood"
                          maxLength={30}
                          placeholder="Bairro"
                          containerClassName="input-container input-container-sm"
                        />
                        <Input
                          type="text"
                          name="reference"
                          id="reference"
                          maxLength={30}
                          placeholder="Ponto referência"
                          containerClassName="input-container input-container-sm"
                        />
                      </div>
                    </div>

                    <div className="delpay-content-address-totals">
                      <strong>
                        <span>SubTotal</span>
                        <br />
                        {formatValue(cart.total)}
                      </strong>

                      <strong>
                        <span>Taxa de entrega</span>
                        <br />+{formatValue(config.delivery_rate)}
                      </strong>

                      <strong>
                        <span>Total</span>
                        <br />
                        {formatValue(Number(cart.total) + Number(config.delivery_rate))}
                      </strong>
                    </div>

                    <h4>Forma pagamento</h4>
                    <Tabs
                      className="delpay-method-payment-container"
                      defaultIndex={payment.method_payment}
                      onSelect={(index) => setPaymentTabSelected(index)}
                    >
                      <TabList className="delpay-content-tablist">
                        <Tab
                          className={
                            tabPaymentSelected === 0
                              ? "delpay-content-tablist-tab tab-selected"
                              : "delpay-content-tablist-tab tab-non-selected"
                          }
                        >
                          <FaMoneyBillWaveAlt />
                          Dinheiro
                        </Tab>
                        <Tab
                          className={
                            tabPaymentSelected === 1
                              ? "delpay-content-tablist-tab tab-selected"
                              : "delpay-content-tablist-tab tab-non-selected"
                          }
                        >
                          <FaCreditCard />
                          Cartão
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <div className="delpay-content-cash">
                          <label>Troco para:</label>
                          <InputCurrency
                            containerClassName="input-money-container input-money-container-sm"
                            name="change_value"
                            id="change_value"
                            max={100000}
                            style={{ textAlign: "right" }}
                            onChange={handleValueChange}
                            disabled={checkedChange}
                          />
                          <div className="delpay-content-cash-change">
                            <CheckboxInput
                              name="nonchange"
                              checked={checkedChange}
                              onChange={handleChangeCheck}
                              options={checkboxOptions}
                            />
                            <label htmlFor="no-change">Sem troco</label>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="delpay-content-card">
                          <label htmlFor="cards">Selecione a bandeira:</label>
                          <Select
                            name="cards"
                            options={cardsOptions}
                            defaultValue={cardsOptions[indexCardSelected]}
                          />
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="page-delpay-withdraw">
                    <h3>ENDEREÇO PARA RETIRADA</h3>
                    <strong>
                      {config.address}, {config.address_number} {config.neighborhood} - {config.city}/{config.state}
                    </strong>
                    <div className="page-delpay-withdraw-total">
                      <strong>
                        <span>Total</span>
                        <br />
                        {formatValue(cart.total)}
                      </strong>
                    </div>
                  </div>
                </TabPanel>

              </Tabs>
            </div>
            <footer className="page-delpay-footer">
              <button type="submit">
                Avançar
                <FaChevronRight />
              </button>
            </footer>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPayment;
