import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ICategory from '../../interfaces/ICategory';
import IUseParams from '../../interfaces/IUseParams';
import api from '../../services/api';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Scrollspy from 'react-scrollspy';

import './styles.css';

const HorizontalMenu: React.FC = () => {
  const { company } = useParams<IUseParams>();
  const [menuCategories, setMenuCategories] = useState<ICategory[]>([]);// const [selectedCategory, setSelectedCategory] = useState(0);
  const [itemsCategories, setItemsCategories] = useState<string[]>([]);
  const [scrolled, setScrolled] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);

  const handleArray = useCallback((data: ICategory[]) => {
    let arrayStr: string[] = [];
    data.map(category => {
      return arrayStr.push(String(category.id));
    });
    setItemsCategories(arrayStr);
  }, []);

  useEffect(() => {
    api.get(`/${company}/categories`).then((response) => {
      setMenuCategories(response.data);
      handleArray(response.data);
    });
  }, [company, handleArray]);

  const handleNav = useCallback((direction: string) => {
    if (navRef.current) {
      if (direction === 'left') {
        return navRef ? (navRef.current.scrollLeft -= 100) : null;
      } else {
        return navRef ? (navRef.current.scrollLeft += 100) : null;
      }
    }
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  let x = ['horizontal-menu'];
  if (scrolled) {
    x.push('scrolled');
  } 

  return (
    <div className={x.join(" ")}>
      <button className="horizontal-menu-nav-button" onClick={() => handleNav('left')}>
        <FaChevronLeft />
      </button>
      <nav className="horizontal-menu-nav" ref={navRef}>
        <Scrollspy items={itemsCategories} currentClassName="category-selected">
          {menuCategories.map(category => (
            <a
              // id={String(category.id)}
              key={category.id}              
              className="horizontal-menu-category-item"
              href={`#${String(category.id)}`}
            >
              {category.description}
            </a>
          ))}
        </Scrollspy>
      </nav>
      <div>
        <button className="horizontal-menu-nav-button" onClick={() => handleNav('right')}>
          <FaChevronRight />
        </button>
      </div>
    </div>

  );
}

export default HorizontalMenu;