import React, { useEffect, useCallback, useState } from "react";

import { FaShoppingCart, FaChevronLeft } from "react-icons/fa";

import formatValue from "../../utils/formatValue";
import Header from "../../components/PageHeader";
import { useCart } from "../../hooks/cart";
import { useDelivery } from "../../hooks/delivery-payment";
import { Link, useHistory, useParams } from "react-router-dom";

import "./styles.css";
import api from "../../services/api";
import { IOrderSubmit, IOrderItemSubmit } from "../../interfaces/IOrderSubmit";
import IUseParams from "../../interfaces/IUseParams";
import { useWorking } from '../../hooks/working';
import { useConfig } from '../../hooks/config';
import removeSpecialsCharacters from "../../utils/removeSpecialsCharacters";

const OrderResume: React.FC = () => {
  const { cart, productsCart, empty } = useCart();
  const { delivery, payment, remove } = useDelivery();
  const history = useHistory();
  const { company } = useParams<IUseParams>();
  const { getToday } = useWorking();
  const { load, config } = useConfig();
  const [btnDisable, setBtnDisable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    load(company);
  }, [company, load]);

  const handleFinish = useCallback(async () => {
    let data = {} as IOrderSubmit;
    //
    data.cart_id = cart.id;
    data.name = delivery.name;
    data.phone = delivery.celphone;

    data.type_delivery = delivery.type_delivery;

    data.cep = delivery.cep ? delivery.cep : '';
    data.address = removeSpecialsCharacters(delivery.address ? delivery.address : '');
    data.number = removeSpecialsCharacters(delivery.number ? delivery.number : '');
    data.complement = removeSpecialsCharacters(delivery.complement ? delivery.complement : '');
    data.neighborhood = removeSpecialsCharacters(delivery.neighborhood ? delivery.neighborhood : '');
    data.reference = removeSpecialsCharacters(delivery.reference ? delivery.reference : '');

    data.method_payment = payment.method_payment ? payment.method_payment : 0;
    data.change_value = payment.change_value ? payment.change_value : 0;
    data.non_change = payment.non_change ? payment.non_change : false;
    data.card_flag = payment.card_flag ? payment.card_flag : '';

    data.observ = '';

    const findedDay = await getToday(company);
    if (findedDay) {      
      data.waiting_time = 
        `${delivery.type_delivery === 0 ? findedDay.minutes_to_delivery : findedDay.minutes_to_withdraw}`
    }

    data.products = [];
    data.products = productsCart.map(item => {

      let product: IOrderItemSubmit = {
        id: '',
        order_id: '',
        product_id: item.id,
        name: item.name,
        description: item.description,
        price: item.price,
        additional_price: item.additional_price,
        quantity: item.quantity,
        subtotal: item.subTotal,
        observ: removeSpecialsCharacters(item.observ),
        additionals: item.additionals,
      }
      return product;
    });

    await api.post(`/${company}/orders`, data);

    remove();
    empty();

    alert("Pedido realizado com sucesso!");

    history.push(`/${company}/`);
  }, [remove, empty, history, delivery, payment, productsCart, company, getToday, cart]);

  if (!cart) {
    history.push(`/${company}`);
    return null;
  }

  return (
    <div className="page-order-resume">
      <Header />

      <div className="page-order-resume-container">

        <Link to={`/${company}/delivery-payment`} id="back-link">
          <FaChevronLeft />
          Voltar
        </Link>
        {cart && (
          <div className="page-order-resume-data">
            <div className="page-order-resume-orientation">
              <h2>RESUMO E IDENTIFICAÇÃO</h2>
              <strong>
                Olá {delivery.name}. <br></br>
                Antes de finalizar o pedido confira se as informações estão corretas.
              </strong>
            </div>

            <h1 id="page-order-resume-total">{formatValue(cart.total)}</h1>

            <div className="page-order-resume-content-info">
              <div className="page-order-resume-div page-order-resume-items">
                <h4 id="page-order-resume-yours-items">
                  <FaShoppingCart /> Seus itens
                </h4>
                {productsCart &&
                  productsCart.map((productCart) => (
                    <div
                      key={productCart.idCart}
                      className="page-order-resume-item"
                    >
                      <div className="page-order-resume-item-nameadditionals">
                        <h5>{productCart.name}</h5>
                        <h6>
                          {productCart.observ}
                        </h6>

                        {productCart.additionals &&
                          productCart.additionals.map((additional) => (
                            <small key={additional.additional_id}>
                              {additional.type === "AD" ?
                                additional.quantity > 1 ?
                                  `+ ${additional.quantity} X ${additional.description},` :
                                  `+ ${additional.description},`
                                : `${additional.description}, `}
                            </small>
                          ))}
                      </div>

                      <div className="page-order-resume-item-pricesqtd">
                        <div className="page-order-resume-item-field">
                          <small>Unit.</small>
                          <strong>{formatValue(productCart.price)}</strong>
                        </div>
                        <div className="page-order-resume-item-field">
                          <small>Qtd.</small>
                          <strong>{productCart.quantity}</strong>
                        </div>
                        <div className="page-order-resume-item-field">
                          <small>Subtotal</small>
                          <strong>{formatValue(productCart.subTotal)}</strong>
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="page-order-resume-items-totals">
                  <h5>Subtotal: {formatValue(cart.subtotal)}</h5>
                  {delivery.type_delivery === 0 &&
                    <h5>Taxa entrega: {formatValue(config.delivery_rate)}</h5>}

                  {delivery.type_delivery === 0 ?
                    <h4>Total: {formatValue(Number(cart.total) + Number(config.delivery_rate))}</h4> :
                    <h4>Total: {formatValue(Number(cart.total))}</h4>}

                </div>
              </div>

              <div className="page-order-resume-contact-delivery-payment">
                <div className="page-order-resume-div page-order-resume-contact">
                  <h4>{delivery.celphone}</h4>
                  <h4>{delivery.name}</h4>
                  <Link to={`/${company}/delivery-payment`}>Editar</Link>
                </div>

                <div className="page-order-resume-div page-order-resume-payment">
                  <h4>$ Forma de pagamento</h4>
                  {delivery.type_delivery === 0 ? (
                    <div className="page-order-resume-detail-payment">
                      {/* <p>{payment.method_payment === 0 ? "Dinheiro" : "Cartão"}</p> */}
                      {payment.method_payment === 0 ? (
                        <div>
                          <strong>Dinheiro</strong>
                          {!payment.non_change && (
                            <p>Troco para: {formatValue(payment.change_value)}</p>
                          )}
                        </div>
                      ) : (
                        <div>
                          <strong>Cartão</strong>
                          <p>{payment.card_flag}</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p>Não possui.</p>
                  )}
                  <Link to={`/${company}/delivery-payment`}>Editar</Link>
                </div>

                <div className="page-order-resume-div page-order-resume-delivery">
                  <h4>Entrega</h4>
                  {/* <strong>{delivery.type_delivery}</strong> */}
                  {delivery.type_delivery === 0 ? (
                    <div className="page-order-resume-delivery-detail">
                      <p>{delivery.cep}</p>
                      <p>
                        {delivery.address},{delivery.number}
                      </p>
                      <p>{delivery.neighborhood}</p>
                      <p>{delivery.complement}</p>
                      <p>{delivery.reference}</p>
                    </div>
                  ) : (
                    <div className="page-order-resume-delivery-withdraw">
                      <p>Retirar no local</p>
                    </div>
                  )}
                  <Link to={`/${company}/delivery-payment`}>Editar</Link>
                </div>
              </div>
            </div>

            <footer>
              <button disabled={btnDisable} type="submit" onClick={() => { setBtnDisable(true); handleFinish() }}>
                Finalizar pedido
              </button>
            </footer>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderResume;
