import React, { createContext, useCallback, useState, useContext } from "react";

import IProduct from '../interfaces/IProduct';


interface ProductContextData {
  product: IProduct;
  include(product: IProduct): Promise<void>;
  exclude(): Promise<void>;
}

interface ProductState {
  product: IProduct;
}

const ProductContext = createContext<ProductContextData>(
  {} as ProductContextData
);

export const ProductProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ProductState>(() => {
    const product = sessionStorage.getItem("@Cardapio-Hnd:product");
    if (product) {
      return { product: JSON.parse(product) };
    }
    return {product: {} as IProduct} as ProductState;
  });

  const include = useCallback(async (product) => {
    sessionStorage.setItem("@Cardapio-Hnd:product", JSON.stringify(product));
    setData({ product });
  }, []);

  const exclude = useCallback(async () => {
    sessionStorage.removeItem("@Cardapio-Hnd:product");
    setData({product: {} as IProduct} as ProductState);
  }, []);

  return (
    <ProductContext.Provider value={{ product: data.product, include, exclude }}>
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct(): ProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("productAuth must be used within an ProductProvider");
  }

  return context;
}

export default ProductContext;
