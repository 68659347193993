import React, { useCallback, useEffect, useState } from 'react';

import './styles.css';
import { useHistory, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useConfig } from '../../hooks/config';
import { useWorking } from '../../hooks/working';
import IUseParams from '../../interfaces/IUseParams';
import { IWorkingHour } from '../../interfaces/IWorkingHour';
import { zonedTimeToUtc } from 'date-fns-tz';
import formatValue from '../../utils/formatValue';

const PageHeader: React.FC = () => {
  const { company } = useParams<IUseParams>();
  const history = useHistory();
  const { config, load } = useConfig();
  const { getToday } = useWorking();

  const [today, setToday] = useState<IWorkingHour>();

  const handleToday = useCallback(async () => {
    const findDay = await getToday(company);

    if (findDay) {
      setToday(findDay);
      console.log('handleToday', findDay);
    }
  }, [company, getToday]);

  function handleWaitingTime() {
    return !today?.working_day ?
      <p>Fechado hoje</p> :
      <div>
        <p>Entrega {today?.minutes_to_delivery} ● {formatValue(config.delivery_rate)}</p>        
        <p>Retirada {today?.minutes_to_withdraw}</p>
      </div>
  };

  useEffect(() => {
    handleToday();
  }, [handleToday]);

  useEffect(() => {
    //
    load(company);
  }, [load, company]);

  return (
    <header className="page-header">
      <div className="top-bar-container">
        <img
          src={config.logo_url}
          alt="logo"
          onClick={() => history.push(`/${company}`)}
        />

        <div className="page-header-info-title">
          <div className="page-header-detail-title">
            <h1>{config.fancy_name}</h1>
          </div>

          <div className="page-header-info">
            <div className="page-header-detail-info">
              <strong>Onde estamos</strong>
              <p>
                {config.city} - {config.state}
              </p>
            </div>

            <div className="page-header-detail-info">
              <strong>Tempo de espera</strong>
              {handleWaitingTime()}
            </div>

            <div className="page-header-detail-info">
              <strong>Horário de atendimento</strong>
              {today?.working_day ? (
                <p>
                  {format(zonedTimeToUtc(parseISO(`${String(today.opening).substr(0, 10)} ${String(today.opening).substr(11, 5)}`), 'America/Sao_Paulo'), 'HH:mm')} às{' '}
                  {format(zonedTimeToUtc(parseISO(`${String(today.opening).substr(0, 10)} ${String(today.closure).substr(11, 5)}`), 'America/Sao_Paulo'), 'HH:mm')}
                </p>
              ) : <p>Fechado hoje</p>}
            </div>
          </div>
        </div>
      </div>     

    </header>
  );
};

export default PageHeader;
