import React, { useEffect, useRef } from "react";

import { useField } from "@unform/core";
import ReactInputMask, { Props as InputProps } from "react-input-mask";

interface Props extends InputProps {
  name: string;
  containerClassName?: string;
}

const InputMask: React.FC<Props> = ({ name, containerClassName, ...rest }) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    error,
    registerField,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    });
  }, [fieldName, registerField]); 

  return (
    <div className={containerClassName}>
      <ReactInputMask
        className={error ? 'input-error' : 'input'}
        ref={inputRef}
        onFocus={clearError}
        defaultValue={defaultValue}
        {...rest}
      />
      {/* {error && <span className="error">{error}</span>} */}
    </div>
  );
};

export default InputMask;
