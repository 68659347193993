import React, { InputHTMLAttributes, useEffect, useRef } from "react";

import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

import "./styles.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  containerClassName?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  containerClassName,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    fieldName,
    defaultValue,
    error,
    registerField,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div className={containerClassName}>
      <input
        className={error ? 'input-error' : 'input'}
        ref={inputRef}
        onFocus={clearError}
        defaultValue={defaultValue}
        {...rest}
      />      
    </div>
  );
};

export default Input;
