import React, { useCallback } from 'react';

import { useHistory, useParams } from "react-router-dom";
import { useCart } from "../../hooks/cart";
import { FaShoppingCart } from "react-icons/fa";

import formatValue from "../../utils/formatValue";
import './styles.css';
import IUseParams from '../../interfaces/IUseParams';

const Footer: React.FC = () => {
  const history = useHistory();
  const { cart } = useCart();

  const { company } = useParams<IUseParams>();

  const handleCart = useCallback(() => {
    history.push(`/${company}/cart`);
  }, [history, company]);

  return (
    <footer className="page-footer-container">
      <button onClick={handleCart}>
        <div className="page-footer-container-cart">
          <FaShoppingCart size={20} color="#FFF" />
          <span>{cart.items}</span>
        </div>
        <strong>Meu Carrinho</strong>
        <strong>{formatValue(!!cart ? cart.total : 0)}</strong>
      </button>
    </footer>
  );
}

export default Footer;