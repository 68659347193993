import React, { InputHTMLAttributes, useEffect, useRef } from "react";

import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";
import CurrencyInput from 'react-currency-input-field';

import "./styles.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  containerClassName?: string;
  step?: number;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  containerClassName,
  step,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    fieldName,
    defaultValue,
    error,
    registerField,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div className={containerClassName}>
      <CurrencyInput       
        placeholder="Digite valor do troco"
        disableGroupSeparators={true}
        decimalScale={2}
        style={{ textAlign: "right" }}
        decimalSeparator=","
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        className={
          error ? 'input-money-error' : disabled ? 'input-money-disable' : 'input-money'}
        ref={inputRef}
        onFocus={clearError}
        defaultValue={defaultValue}
        step={1}
        {...rest}
      />
    </div>
  );
};

export default Input;
