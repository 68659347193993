import React, { useState } from 'react';

import IAdditional from '../../interfaces/IAdditional';

import './styles.css';

interface Props {
  optional: IAdditional;
  handleClick: () => void;
}

const Optional = ({ optional, handleClick }: Props) => {
  const [check, setCheck] = useState(false);

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    optional.check = event.target.checked;
    optional.quantity = event.target.checked ? 1 : 0;
    setCheck(optional.check);
    handleClick();
  }

  return (
    <div className={check === true ? "optionalcomp-container" : "optionalcomp-container uncheck-opc"}>
      <h4 className="optionalcomp-description">{optional.description}</h4>

      <div className="optionalcomp-btn-container">
        <h4 className="optionalcomp-price">grátis</h4>
        <input
          type="checkbox"
          className="optionalcomp-chk"
          onChange={handleCheck}
        />
      </div>
    </div>
  );
}

export default Optional;