import React, { useEffect, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { GoSearch } from 'react-icons/go';
import { MdArrowBack } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import ProductItem from '../../components/ProductItem';
import IProduct from '../../interfaces/IProduct';
import IUseParams from '../../interfaces/IUseParams';
import api from '../../services/api';
import EmptySearchImg from '../../assets/empty-search.png';

import './styles.css';
import "../../styles/global.css";

const Search = () => {
  const history = useHistory();
  const { company } = useParams<IUseParams>();
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState<IProduct[]>([]);

  const handleBack = () => {
    history.goBack();
  }

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  }

  const handleClear = () => {
    setSearch('');
  }

  const handleFindProducts = () => {
    api
      .get(`${company}/products`, {
        params: {
          order_field: 'name',
          ASCDESC: 'ASC',
          category: 0,
          search_name: search,
        },
      })
      .then(response => {
        setProducts(response.data);
      });
  }

  useEffect(() => {
    if (search.length === 0) {
      setProducts([]);
      return;
    }
    handleFindProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="page-search-container">

      <div className="page-search-header">

        <div className="page-search-header-input">
          <button
            id="page-search-button-back"
            type="button"
            onClick={handleBack}
          >
            <MdArrowBack />
          </button>

          <div className="page-search-input-container">
            <GoSearch />
            <input
              type="text"
              placeholder='Buscar produtos'
              onChange={handleSearch}
              value={search}
              autoFocus
            />

            <button
              id="page-search-button-clear"
              type="button"
              onClick={handleClear}
            >
              <FiXCircle />
            </button>
          </div>

        </div>


        <div className="page-search-line" />
      </div>



      {products.length === 0
        ?
        <div className="page-search-empty">
          <img
            src={EmptySearchImg}
            alt=""
          />
        </div>
        :
        <div className="page-search-list">
          {
            products.map(
              product =>
                <ProductItem
                  key={product.id}
                  product={product}
                  page={'Search'}
                />
            )}
        </div>
      }
    </div>
  );
}

export default Search;

