import React, { useCallback, useEffect } from "react";

import { useHistory, Link, useParams } from "react-router-dom";
import { FaTrashAlt, FaChevronLeft } from "react-icons/fa";

import { useCart } from "../../hooks/cart";
import Header from "../../components/PageHeader";
import QtdButton from "../../components/QtdButton";

import formatValue from "../../utils/formatValue";
import "./styles.css";
import IProductCart from "../../interfaces/IProductCart";
import { FiXCircle } from "react-icons/fi";
import { useDelivery } from "../../hooks/delivery-payment";
import IUseParams from "../../interfaces/IUseParams";
import { useWorking } from "../../hooks/working";

const Cart: React.FC = () => {
  const history = useHistory();
  const { cart, productsCart, exclude, modify, empty } = useCart();
  const { remove } = useDelivery();
  const { isOpened } = useWorking();

  const { company } = useParams<IUseParams>();

  useEffect(() => {
    if (!cart) {
      history.push(`/${company}`);
      return;
    }
  }, [history, cart, company]);

  const handleDeleteProduct = useCallback(
    async (productCart) => {
      await exclude(productCart);
    },
    [exclude]
  );

  const handleModifyQuantity = useCallback(
    (productCart: IProductCart, newQuantity: number) => {
      productCart.quantity = newQuantity;
      productCart.subTotal = productCart.price * newQuantity;
      modify(productCart);
    },
    [modify]
  );

  const handleEmptyCart = useCallback(() => {
    empty();
  }, [empty]);

  const handleNext = useCallback(() => {
    if (!isOpened) {
      return;
    }

    remove();

    history.replace(`/${company}/delivery-payment`);

  }, [history, remove, company, isOpened]);

  if (!cart) {
    history.push(`/${company}`);
    return null;
  }

  return (
    <div className="page-cart">
      <Header />

      <div className="page-cart-container">

        <Link to={`/${company}/`} id="back-link">
          <FaChevronLeft />
          Continuar comprando
        </Link>

        <div className="page-cart-data">
          <h2 id="title-cart">Seu Pedido</h2>

          <section className="list-products-container">
            {productsCart &&
              productsCart.map((productCart) => (
                <div key={productCart.idCart} className="cart-product-item">
                  <div className="cart-product-item-img-name">
                    <div className="cart-product-item-img-container">
                      <img
                        src={productCart.image_url}
                        onError={(e) => {
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = require("../../assets/not-found-icon.jpg");
                        }}
                        alt="produto imagem"
                      />
                    </div>

                    <div className="cart-product-item-titleadditionals">
                      <h4>{productCart.name}</h4>
                      <h5>
                        {productCart.observ}
                      </h5>

                      {productCart.additionals &&
                        productCart.additionals.map((additional) => (
                          <small>
                            {additional.type === "AD"
                              ?
                              additional.quantity > 1 ?
                                `+ ${additional.quantity} X ${additional.description},` :
                                `+ ${additional.description},`
                              : `${additional.description}, `}
                          </small>
                        ))}

                    </div>
                  </div>

                  <div className="cart-product-item-prices">
                    <div className="cart-product-item-prices-unit">
                      <small>Unit.</small>
                      <strong>{formatValue(productCart.price)}</strong>
                    </div>
                    <div className="cart-product-item-prices-subtotal">
                      <small>Subtotal</small>
                      <strong>{formatValue(productCart.subTotal)}</strong>
                    </div>
                  </div>

                  <div className="cart-product-item-qtd">
                    <QtdButton
                      onClickQtd={(newQuantity) =>
                        handleModifyQuantity(productCart, newQuantity)
                      }
                      initialQuantity={productCart.quantity}
                    />
                    <button
                      className="btn-delete"
                      onClick={() => handleDeleteProduct(productCart)}
                    >
                      <FiXCircle size={18} />
                    </button>
                  </div>
                </div>
              ))}
          </section>

          <h3 id="total-value-cart">
            Total: {formatValue(cart ? cart.total : 0)}
          </h3>

          <footer>
            <button id="button-empty-cart" onClick={handleEmptyCart}>
              <FaTrashAlt />
              Esvaziar carrinho
            </button>
            <button id="button-next" onClick={handleNext}>
              Avançar
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Cart;
