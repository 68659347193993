import React, { useState, useCallback, useEffect, useRef } from 'react';

import { GiKnifeFork } from 'react-icons/gi';
import { FiClock } from 'react-icons/fi';
import { GoSearch } from 'react-icons/go';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useCart } from '../../hooks/cart';

import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import ProductItem from '../../components/ProductItem';

import IProduct from '../../interfaces/IProduct';
import ICategory from '../../interfaces/ICategory';

import { useConfig } from '../../hooks/config';

import IUseParams from '../../interfaces/IUseParams';
import './styles.css';
import { useWorking } from '../../hooks/working';
import HorizontalMenu from '../../components/HorizontalMenu';
import { Message } from '../../components/Message';
import { IWorkingHour } from '../../interfaces/IWorkingHour';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const Home: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  // const [search, setSearch] = useState("");
  const [pageExists, setPageExists] = useState(true);
  const { config } = useConfig();
  const { cart } = useCart();
  const { isOpen, todayIsOpen, isOpened } = useWorking();
  const history = useHistory();
  const [workingHours, setWorkingHours] = useState<IWorkingHour[]>([]);

  const { company } = useParams<IUseParams>();

  const sectionRef = useRef(null);

  // find products
  useEffect(() => {
    api
      .get(`${company}/products`, {
        params: {
          order_field: 'name',
          ASCDESC: 'ASC',
          category: 0,
          search_name: '',
        },
      })
      .then(response => {
        setProducts(response.data);
      });
  }, [company]);

  // find categories
  useEffect(() => {
    api.get(`/${company}/categories`).then(response => {
      setCategories(response.data);
    });
  }, [company]);

  // calculated working hours 
  const handleOpening = useCallback(async () => {
    const open = await todayIsOpen(company);
    isOpen(open);
  }, [company, todayIsOpen, isOpen]);

  useEffect(() => {
    api.get<IWorkingHour[]>(`/${company}/workinghours`).then((response) => {
      const days = response.data;
      setWorkingHours(days);
    });
  }, [company]);

  useEffect(() => {
    handleOpening();
  }, [handleOpening]);

  // verify company exists
  useEffect(() => {
    api
      .get(`/companies/${company}`)
      .then(response => {
        setPageExists(response.data !== '' || response.data);
      })
      .catch(() => {
        setPageExists(false);
      });
  }, [company, pageExists]);

  if (!pageExists) {
    history.push(`/${company}/404`);
    return null;
  }

  return (
    <>
      <div className="page-home-container">

        <PageHeader />

        <div id="page-home" className="container">

          <div className="page-home-infos">

            <div className="page-home-infos-segment-address">
              <small>
                <GiKnifeFork />
                {config.segment}
              </small>
            </div>

            <div className="page-home-btn-open">
              <div
                // to={`/${company}/working`}
                className={`page-home-infos-openclose ${isOpened ? 'page-home-infos-open' : 'page-home-infos-close'
                  }`}
              >
                <FiClock />
                <strong>
                  {isOpened ? 'Estamos abertos' : 'Estamos fechados'}
                </strong>

              </div>

              <div className="page-home-working-days">

                {workingHours.map((day) => {
                  return (
                    <div className="page-home-working-days-item">
                      <strong>{day.name_day}</strong>
                      {
                        day.working_day ?
                          (<p>
                            {"Das "}
                            {format(zonedTimeToUtc(parseISO(`${String(day.opening).substr(0, 10)} ${String(day.opening).substr(11, 5)}`), 'America/Sao_Paulo'), 'HH:mm')}{" às "}
                            {format(zonedTimeToUtc(parseISO(`${String(day.closure).substr(0, 10)} ${String(day.closure).substr(11, 5)}`), 'America/Sao_Paulo'), 'HH:mm')}
                          </p>)
                          :
                          <p>
                            Fechado
                          </p>
                      }

                    </div>
                  )

                }
                )}
              </div>
            </div>

          </div>

          <div className="page-home-message">
            <Message />
          </div>

          <div className="page-home-search">
            <Link
              to={`/${company}/search`}
            >
              <GoSearch />
            </Link>
          </div>

          <div className="page-home-content">
            <div className="side-menu" />

            <div className="page-home-desccategory-list">
              <HorizontalMenu />

              <div className="page-home-desccategory-search" />

              {categories.map(categorySection => (
                <section
                  key={categorySection.id}
                  className="page-home-category"
                  id={String(categorySection.id)}
                  ref={sectionRef}
                >
                  <div className="page-home-category-title">
                    <h3>{categorySection.description}</h3>
                  </div>
                  <div key={categorySection.id} className="page-home-list">
                    {products.map(
                      product =>
                        product.category_id === categorySection.id && (
                          <ProductItem
                            key={product.id}
                            product={product}
                            page={'Home'}
                          />
                        ),
                    )}
                  </div>

                </section>
              ))}
            </div>
          </div>
        </div>
        {cart && <Footer />}
      </div>

    </>
  );
};

export default Home;
