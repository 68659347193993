import React, { useState } from 'react';

import IAdditional from '../../interfaces/IAdditional';
import formatValue from '../../utils/formatValue';
import QtdButtonAds from '../QtdButtonAds';

import './styles.css';

interface Props {
  additional: IAdditional;
  handleClick: () => void;
}

const Additional = ({ additional, handleClick }: Props) => {
  const [quantity, setQuantity] = useState(0);

  function handleOperation(sum: boolean) {
    // zero
    if (!sum && additional.quantity === 0) return;

    additional.quantity = sum
      ? additional.quantity + 1
      : additional.quantity - 1;

    setQuantity(additional.quantity);
    handleClick();
  }

  return (
    <div className={quantity === 0 ? 'additionalcomp-container uncheck' : 'additionalcomp-container'}>
      <h4 className="additionalcomp-description">{additional.description}</h4>

      <div className="additionalcomp-btn-container">
        <h4 className="additionalcomp-price">+{formatValue(additional.price)}</h4>
        <QtdButtonAds
          quantity={quantity}
          handleAdd={() => handleOperation(true)}
          handleRemove={() => handleOperation(false)}
        />
      </div>
    </div>
  );
}

export default Additional;