import React, { createContext, useState, useContext, useCallback } from "react";
import IConfig from "../interfaces/IConfig";
import api from "../services/api";

interface ConfigContextData {
  config: IConfig;
  load(company: string): Promise<void>;
}

interface ConfigState {
  config: IConfig;
}

const ConfigContext = createContext<ConfigContextData>({} as ConfigContextData);

export const ConfigProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ConfigState>(() => {
    const config = localStorage.getItem("@Cardapio-Hnd:config");
    if (config) {
      return { config: JSON.parse(config) };
    }
    return {config: {} as IConfig} as ConfigState;
  });
  
  const load = useCallback(async (company) => {
    let config: IConfig = {} as IConfig;
    const response = await api.get(`/${company}/config`);
    config = response.data;
    sessionStorage.setItem("@Cardapio-Hnd:config", JSON.stringify(config));
    setData({ config });

    document.documentElement.style.setProperty("--color-banner", config.color_banner);
    document.documentElement.style.setProperty("--color-font-banner", config.color_font_banner);
    document.documentElement.style.setProperty("--color-font-primary", config.color_font_primary);
    document.documentElement.style.setProperty("--color-font-secundary", config.color_font_secundary);

    console.log('font-banner: ', config.color_font_banner);
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        config: data.config,
        load,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig(): ConfigContextData {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error("Config must be used within an ConfigProvider");
  }

  return context;
}

export default ConfigContext;
