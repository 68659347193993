import React, { useCallback, useState, useEffect } from 'react';

import { MdArrowBack } from 'react-icons/md';
import { uuid } from 'uuidv4';
import { useHistory, useParams } from 'react-router-dom';
import ButtonQuantity from '../../components/QtdButton';

import { useProduct } from '../../hooks/product';
import IProductCart from '../../interfaces/IProductCart';
import IAdditional from '../../interfaces/IAdditional';
import formatValue from '../../utils/formatValue';
import { useCart } from '../../hooks/cart';

import api from '../../services/api';

import './styles.css';
import IUseParams from '../../interfaces/IUseParams';
import { useConfig } from '../../hooks/config';
import { useWorking } from '../../hooks/working';
import Additional from '../../components/Additional';
import Optional from '../../components/Optional';

const Detail: React.FC = () => {
  const { config, load } = useConfig();
  const { product, exclude } = useProduct();
  const { include } = useCart();
  const { isOpened, todayIsOpen, isOpen } = useWorking();
  const history = useHistory();

  const [quantity, setQuantity] = useState(1);
  const [subTotal, setSubTotal] = useState(0);
  const [priceUnitar, setPriceUnitar] = useState(0);
  const [observ, setObserv] = useState('');
  const [totalAdditional, setTotalAdditional] = useState(0);
  const [additionals, setAdditionals] = useState<IAdditional[]>([]);
  const [optionals, setOptionals] = useState<IAdditional[]>([]);
  const [selectedAdditionals, setSelectedAdditionals] = useState<IAdditional[]>(
    [],
  );

  const { company } = useParams<IUseParams>();

  // calculated working hours 
  const handleOpening = useCallback(async () => {
    const open = await todayIsOpen(company);
    isOpen(open);
  }, [company, todayIsOpen, isOpen]);

  useEffect(() => {
    handleOpening();
  }, [handleOpening]);

  const getAdditionals = useCallback(() => {
    api
      .get(`${company}/additionals/subgroup`, {
        params: {
          subgroup: product.subgroup_id,
        },
      })
      .then(response => {
        const listAdd: IAdditional[] = [];
        const listOpc: IAdditional[] = [];
        for (let index = 0; index < response.data.length; index++) {
          const additional: IAdditional = {
            additional_id: response.data[index].additional_id,
            description: response.data[index].additional.description,
            type: response.data[index].additional.type,
            price: response.data[index].additional.price,
            quantity: 0,
            check: false
          };

          if (response.data[index].additional.type === 'AD') {
            listAdd.push(additional);
          } else
            listOpc.push(additional);
        }
        setOptionals([...listOpc]);
        setAdditionals([...listAdd]);
      });
  }, [company, product]);

  useEffect(() => {
    load(company);
    getAdditionals();
  }, [getAdditionals, load, company]);

  const calculateTotalAdditionals = useCallback(() => {
    const { total } = selectedAdditionals.reduce(
      (accumulator, additional) => {
        accumulator.total += Number(additional.price * additional.quantity);
        return accumulator;
      },
      {
        total: 0,
      },
    );
    setTotalAdditional(total);
  }, [selectedAdditionals]);

  useEffect(() => {
    calculateTotalAdditionals();
    const unitar = Number(product.price) + Number(totalAdditional);
    setPriceUnitar(unitar);
    setSubTotal(unitar * quantity);
  }, [product.price, quantity, totalAdditional, calculateTotalAdditionals]);

  const handleButtonBack = () => {
    history.push(`/${company}/`);
    // history.goBack();
  };

  const handleConfirm = useCallback(async () => {
    if (!isOpened) {
      return;
    }

    const productCart: IProductCart = {
      ...product,
      company_id: config.company_id,
      price: priceUnitar,
      idCart: uuid(),
      additional_price: totalAdditional,
      quantity,
      subTotal,
      observ,
      additionals: selectedAdditionals,
    };

    await include(productCart);
    await exclude();

    history.push(`/${company}/`);
  }, [
    include,
    exclude,
    product,
    quantity,
    selectedAdditionals,
    priceUnitar,
    subTotal,
    totalAdditional,
    config.company_id,
    isOpened,
    history,
    company,
    observ
  ]);

  const handleCheckAdditional = useCallback(
    (selectAdditional: IAdditional) => {

      const filteredItems = selectedAdditionals.filter(
        item => item.additional_id !== selectAdditional.additional_id,
      );

      if (selectAdditional.quantity > 0) {
        setSelectedAdditionals([...filteredItems, selectAdditional]);
      } else {
        setSelectedAdditionals(filteredItems);
      }
    },
    [selectedAdditionals],
  );

  return (
    <div id="modal" className="page-detail-modal">
      <div id="container" className="page-detail-container">
        <div className="title-container">
          <button id="button-close" type="button" onClick={handleButtonBack}>
            <MdArrowBack />
          </button>
          <h4>Voltar ao cardápio</h4>
        </div>
        <div className="img-container">
          <img
            src={product.image_url}
            onError={e => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = require('../../assets/not-found-icon.jpg');
            }}
            alt="produto imagem"
          />
        </div>
        <h2>{product.name}</h2>

        <strong>{product.description}</strong>

        {additionals.length > 0 && (
          <div className="page-detail-additionals">

            <div className="page-detail-additionals-title">
              <h3>Adicionais</h3>
            </div>

            {additionals.map(additional => (
              <div key={additional.additional_id} className="row">
                <Additional additional={additional} handleClick={() => handleCheckAdditional(additional)} />
              </div>
            ))}
          </div>
        )}

        {optionals.length > 0 && (
          <div className="page-detail-additionals">
            <div className="page-detail-additionals-title">
              <h3>Opcionais</h3>
            </div>
            {optionals.map(optional => (
              <div key={optional.additional_id} className="row">
                <Optional optional={optional} handleClick={() => handleCheckAdditional(optional)} />
              </div>
            ))}
          </div>
        )}

        <div className="observ-container">
          <textarea placeholder="Alguma observação?" onChange={(value) => setObserv(value.currentTarget.value)} />
        </div>

        <footer>
          <ButtonQuantity
            // onClickQtd={selectQuantity => handleQtd(selectQuantity)}
            onClickQtd={selectQuantity => setQuantity(selectQuantity)}
            initialQuantity={1}
          />
          <button
            className={`button-confirm ${isOpened ? '' : 'block'}`}
            onClick={handleConfirm}
          >
            {isOpened ? 'Adicionar' : 'Fechado no momento!'}
            <br />
            {formatValue(subTotal)}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Detail;
