import React, { createContext, useCallback, useState, useContext } from "react";
import { uuid } from 'uuidv4';
import ICart from "../interfaces/ICart";

import IProductCart from "../interfaces/IProductCart";

interface CartContextData {
  productsCart: IProductCart[];
  cart: ICart;
  include(product: IProductCart): Promise<void>;
  exclude(product: IProductCart): Promise<void>;
  modify(product: IProductCart): Promise<void>;
  empty(): void;
}

interface CartState {
  productsCart: IProductCart[];
  cart: ICart;
}

const CartContext = createContext<CartContextData>({} as CartContextData);

function handleCart(productsCart: IProductCart[], cart: ICart): ICart {
  const { total, items } = productsCart.reduce(
    (accumulator, productCart) => {
      accumulator.total += Number(productCart.subTotal);
      accumulator.items += 1;
      return accumulator;
    },
    {
      total: 0,
      items: 0,
    }
  );

  const new_cart: ICart = {
    company_id: cart.company_id,
    id: cart.id,
    subtotal: total,
    total,
    observ: cart.observ,
    items,
  };

  return new_cart;
}

export const CartProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<CartState>(() => {
    // const productsCart = sessionStorage.getItem(`@Cardapio-Hnd:products-cart`);
    // const cart = sessionStorage.getItem(`@Cardapio-Hnd:cart`);

    // if (productsCart && cart) {
    //   return { cart: JSON.parse(cart), productsCart: JSON.parse(productsCart) };
    // }
    return {} as CartState;
  });

  const empty = useCallback(() => {
    // sessionStorage.removeItem(`@Cardapio-Hnd:products-cart`);
    // sessionStorage.removeItem(`@Cardapio-Hnd:cart`);
    setData({} as CartState);
  }, []);

  const include = useCallback(
    async (productCart: IProductCart) => {
      let { productsCart, cart } = data;

      if (!productsCart) {
        productsCart = [];
      }

      if (!cart) {
        cart = {id: uuid()} as ICart;
      }

      if (cart.company_id !== productCart.company_id) {
        empty();
      }

      productsCart.push(productCart);

      let new_cart = handleCart(productsCart, cart);
      new_cart.company_id = productCart.company_id;

      setData({ productsCart, cart: new_cart });
    },
    [data, empty]
  );

  const exclude = useCallback(
    async (productCart: IProductCart) => {
      const { productsCart, cart } = data;

      const findedIndex = productsCart.findIndex(
        (product) => product.idCart === productCart.idCart
      );

      if (findedIndex < 0) {
        return;
      }

      productsCart.splice(findedIndex, 1);

      const new_cart = handleCart(productsCart, cart);

      if (new_cart.total > 0) {
        // sessionStorage.setItem(
        //   `@Cardapio-Hnd:products-cart`,
        //   JSON.stringify(productsCart)
        // );

        // sessionStorage.setItem("@Cardapio-Hnd:cart", JSON.stringify(new_cart));
        setData({ productsCart, cart: new_cart });
      } else {
        // sessionStorage.removeItem(`@Cardapio-Hnd:products-cart`);
        // sessionStorage.removeItem(`@Cardapio-Hnd:cart`);
        setData({} as CartState);
      }
    },
    [data]
  );

  const modify = useCallback(
    async (productCart: IProductCart) => {
      const { productsCart, cart } = data;

      const findedIndex = productsCart.findIndex(
        (product) => product.idCart === productCart.idCart
      );

      if (findedIndex < 0) {
        return;
      }

      productsCart[findedIndex] = productCart;

      const new_cart = handleCart(productsCart, cart);

      sessionStorage.setItem(
        `@Cardapio-Hnd:products-cart`,
        JSON.stringify(productsCart)
      );
      sessionStorage.setItem(`@Cardapio-Hnd:cart`, JSON.stringify(new_cart));
      setData({ productsCart, cart: new_cart });

      // refreshDatasessionStorage();
    },
    [data]
  );

  return (
    <CartContext.Provider
      value={{
        productsCart: data.productsCart,
        cart: data.cart,
        include,
        exclude,
        modify,
        empty,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartContextData {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error("Cart must be used within an ProductProvider");
  }

  return context;
}

export default CartContext;
