import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { useConfig } from "../../hooks/config";
import IUseParams from "../../interfaces/IUseParams";
import { IWorkingHour } from "../../interfaces/IWorkingHour";
import api from "../../services/api";
import { format, zonedTimeToUtc } from 'date-fns-tz';

import "./styles.css";
import { parseISO } from "date-fns";

const WorkingHours: React.FC = () => {
  const [workingHours, setWorkingHours] = useState<IWorkingHour[]>([]);
  const { company } = useParams<IUseParams>();
  const { config } = useConfig();

  useEffect(() => {
    api.get<IWorkingHour[]>(`/${company}/workinghours`).then((response) => {
      const days = response.data;
      setWorkingHours(days);
    });
  }, [company]);

  return (
    <div className="page-working">
      <PageHeader />
      <div className="page-working-container">

        <Link to={`/${company}/`} id="back-link-working">
          <FaChevronLeft />
          Voltar
        </Link>

        <div className="page-working-about">
          <h3>Sobre a loja</h3>
          <p>{config.about}</p>
        </div>

        <div className="page-working-days">
          <div className="page-working-days-clock">
            <FiClock />
            <h3>Horário de funcionamento</h3>
          </div>
          {workingHours.map((day) => {            
            return (
              <div className="page-working-days-day">
                <strong>{day.name_day}</strong>
                {day.working_day ? (
                  <p>
                    {format(zonedTimeToUtc(parseISO(`${String(day.opening).substr(0,10)} ${String(day.opening).substr(11,5)}`), 'America/Sao_Paulo'), 'HH:mm')} -{" "}
                    {format(zonedTimeToUtc(parseISO(`${String(day.closure).substr(0,10)} ${String(day.closure).substr(11,5)}`), 'America/Sao_Paulo'), 'HH:mm')}
                  </p>
                ) : (
                    <p>
                      Fechado
                    </p>
                  )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkingHours;
