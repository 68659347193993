import { addDays, format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router';
import OrderReportLine from '../../components/OrderReportLine';

import Header from "../../components/PageHeader";
import { IOrderApi } from '../../interfaces/IOrderApi';
import IUseParams from '../../interfaces/IUseParams';
import api from '../../services/api';

import './styles.css';

const OrdersReport: React.FC = () => {
  const { company, id } = useParams<IUseParams>();
  const [orders, setOrders] = useState<IOrderApi[]>([]);
  const [pageExists, setPageExists] = useState(true);
  const history = useHistory();

  // function to get orders in api
  const handleGetOrders = useCallback(() => {
    api.get(`${company}/orders`, {
      params: {
        company_id: '',
        created_from: `${format(addDays(new Date(), -2), 'yyyy/MM/dd')} 00:00:00.00000`,
        created_to: `${format(addDays(new Date(), 1), 'yyyy/MM/dd')} 23:59:59.00000`,
      }
    }).then(response => {
      setOrders(response.data);
    })
  }, [company]);

  // get orders in api
  useEffect(() => {
    handleGetOrders();
  }, [handleGetOrders]);

  // verify id company exists
  useEffect(() => {
    api
      .get(`/companies/id/${id}`)
      .then(response => {
        setPageExists(response.data !== '' || response.data);
      })
      .catch(() => {
        setPageExists(false);
      });
  }, [company, pageExists, id]);

  if (!pageExists) {
    history.push(`/${company}/404`);
    return null;
  }

  return (
    <div className="page-orders-report">
      <Header />

      <h1 className="page-orders-report-title">Pedidos realizados</h1>

      <div className="page-orders-report-btn-container">
        <button onClick={() => handleGetOrders()}>
          <FaSync />
          Atualizar
        </button>
      </div>

      <div className="page-orders-report-orders">
        <div className="page-orders-report-table">
          <table>

            <thead>
              <tr>
                <th className="page-orders-report-table-none-th"></th>
                <th className="page-orders-report-table-large-th">Cliente</th>
                <th className="page-orders-report-table-small-th">Phone</th>
                <th className="page-orders-report-table-large-th">Endereço</th>
                <th className="page-orders-report-table-small-th">Data/Hora</th>
                <th>Já importado?</th>
              </tr>

            </thead>
            <tbody>
              {orders.map(order => {
                return (
                  <OrderReportLine key={order.id} order={order} />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>



    </div>
  )
}

export default OrdersReport;