import React from "react";

import { ConfigProvider } from "./config";
import { ProductProvider } from "./product";
import { CartProvider } from "./cart";
import { DeliveryProvider } from "./delivery-payment";
import { WorkingProvider } from "./working";

const AppProvider: React.FC = ({ children }) => (
  <ConfigProvider>
    <CartProvider>
      <DeliveryProvider>
        <ProductProvider>
          <WorkingProvider>{children}</WorkingProvider>
        </ProductProvider>
      </DeliveryProvider>
    </CartProvider>
  </ConfigProvider>
);

export default AppProvider;
