import React, { createContext, useCallback, useState, useContext } from "react";

import IDelivery from "../interfaces/IDelivery";
import IPayment from "../interfaces/IPayment";

interface DeliveryContextData {
  delivery: IDelivery;
  payment: IPayment;
  include(delivery: IDelivery, payment: IPayment): Promise<void>;
  remove(): Promise<void>;
}

interface DeliveryState {
  delivery: IDelivery;
  payment: IPayment;
}

const DeliveryContext = createContext<DeliveryContextData>({
  delivery: {} as IDelivery,
  payment: {} as IPayment,
} as DeliveryContextData);

export const DeliveryProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<DeliveryState>(() => {
    const delivery = sessionStorage.getItem("@Cardapio-Hnd:delivery");
    const payment = sessionStorage.getItem("@Cardapio-Hnd:payment");

    if (delivery && payment) {
      return { delivery: JSON.parse(delivery), payment: JSON.parse(payment) };
    }

    return {
      delivery: { type_delivery: 0,   } as IDelivery,
      payment: { method_payment: 0, change_value: 0, card_flag: '', non_change: false } as IPayment,
    } as DeliveryState;
  });

  const include = useCallback(async (delivery, payment) => {
    setData({ delivery, payment });

    sessionStorage.setItem("@Cardapio-Hnd:delivery", JSON.stringify(delivery));
    sessionStorage.setItem("@Cardapio-Hnd:payment", JSON.stringify(payment));
  }, []);

  const remove = useCallback(async () => {
    sessionStorage.removeItem("@Cardapio-Hnd:delivery");
    sessionStorage.removeItem("@Cardapio-Hnd:payment");
    setData({
      delivery: { type_delivery: 0 } as IDelivery,
      payment: { method_payment: 0, change_value: 0, card_flag: '', non_change: false } as IPayment,
    } as DeliveryState);
  }, []);

  return (
    <DeliveryContext.Provider
      value={{
        delivery: data.delivery,
        payment: data.payment,
        include,
        remove,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};

export function useDelivery(): DeliveryContextData {
  const context = useContext(DeliveryContext);

  if (!context) {
    throw new Error("DeliveryProvider");
  }

  return context;
}

export default DeliveryContext;
