import React from 'react';

import './styles.css';

const NotFound404: React.FC = () => {
  return (
    <div className="container-404">
      <h1>AHHH NÃO!! =/</h1>
      <h2>Não encontramos a página solicitada!</h2>
      <h2>Confira se o link esta correto e tente novamente</h2>
    </div>
  );
}

export default NotFound404;