import { addHours, format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { useState } from 'react';
import { FaPlus, FaMinus, FaCircle } from 'react-icons/fa';

import { IOrderApi } from '../../interfaces/IOrderApi';
import formatValue from '../../utils/formatValue';

import './styles.css';

interface propsOrder {
  order: IOrderApi;
}

const OrderReportLine: React.FC<propsOrder> = (props) => {
  const [expand, setExpand] = useState(false);

  return (
    <>
      <tr>
        <td>
          <button id="page-order-report-item-btn" onClick={() => setExpand(!expand)}>
            {expand === true ? <FaMinus /> : <FaPlus />}
          </button>
        </td>
        <td>{props.order.name}</td>
        <td>{props.order.phone}</td>
        <td>{props.order.type_delivery === 0 ? 
          `${props.order.address}, ${props.order.number} - ${props.order.neighborhood}`  : 'Retirar'}</td>
        <td>
          {format(addHours(zonedTimeToUtc(parseISO(
            `${String(props.order.created_at).substr(0, 10)} ${String(props.order.created_at).substr(11, 5)}`), 'America/Sao_Paulo'),-3), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>
          <div className={
            `page-order-report-item-imported 
              ${props.order.already_imported === true ? 'item-imported-true' : 'item-imported-false'}`
          }>
            <FaCircle />
          </div>
        </td>
      </tr>
      {expand && (
        <td colSpan={5}>
          <div className="table-tr-products">
            <div className="page-order-report-div page-order-report-items">
              {props.order.order_products && props.order.order_products.map((productCart) => (
                <div
                  key={productCart.id}
                  className="page-order-report-item"
                >
                  <div className="page-order-report-item-nameadditionals">
                    <h5>{productCart.name}</h5>
                    {productCart.observ !== '' && (
                      <><small>{productCart.observ}</small><br></br></>
                    )}
                    
                    {productCart.additionals &&
                      productCart.additionals.map((additional) => (
                        <small key={additional.additional_id}>
                          {additional.type === "AD"
                            ? `+${additional.description}, `
                            : `${additional.description}, `}
                        </small>
                      ))}
                  </div>

                  <div className="page-order-report-item-pricesqtd">
                    <div className="page-order-report-item-field">
                      <small>Unit.</small>
                      <strong>{formatValue(productCart.price)}</strong>
                    </div>
                    <div className="page-order-report-item-field">
                      <small>Qtd.</small>
                      <strong>{productCart.quantity}</strong>
                    </div>
                    <div className="page-order-report-item-field">
                      <small>Subtotal</small>
                      <strong>{formatValue(productCart.subtotal)}</strong>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>

        </td>
      )}

    </>
  );
}

export default OrderReportLine;