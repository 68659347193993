import React from "react";

import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Cart from "../pages/Cart";
import DeliveryPayment from "../pages/DeliveryPayment";
import OrderResume from "../pages/OrderResume";
import NotFound404 from '../pages/NotFound404';
import WorkingHours from "../pages/WorkingHours";
import Detail from "../pages/Detail";
import OrdersReport from "../pages/OrdersReport";
import OrderMonitor from "../pages/OrderMonitor";
import Search from "../pages/Search";

import "../styles/global.css";
const Routes: React.FC = () => {

  return (
    <Switch>
      <Route path="/:company/" exact component={Home} />
      <Route path="/:company/cart" exact component={Cart} />
      <Route path="/:company/search" exact component={Search} />
      <Route path="/:company/delivery-payment" exact component={DeliveryPayment} />
      <Route path="/:company/order-resume" exact component={OrderResume} />
      <Route path="/:company/order/:id" exact component={OrderMonitor} />
      <Route path="/:company/404" exact component={NotFound404} />
      <Route path="/:company/working" exact component={WorkingHours} />
      <Route path="/:company/detail" exact component={Detail} />
      <Route path="/:company/:id" exact component={OrdersReport} />      
      
    </Switch>  
  );

  
};

export default Routes;
