import React, { useRef, ChangeEvent, useCallback, useState, useEffect } from "react";

import { FiPlus, FiMinus } from "react-icons/fi";

import "./styles.css";

interface ButtonQuantityProps {
  onClickQtd: (newquantity: number) => void;
  initialQuantity: number;
}

const QtdButton: React.FC<ButtonQuantityProps> = ({ onClickQtd, initialQuantity }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [quantity, setQuantity] = useState(initialQuantity);
  const [returnQuantity, setReturnQuantity] = useState(0);

 
  // const handleOnClickQtd = useCallback((value: number) => {
  //   onClickQtd(returnQuantity);
  // }, []);

  useEffect(() => {
    onClickQtd(returnQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnQuantity]);

  useEffect(() => {
    setReturnQuantity(quantity);
  }, [quantity]);

  const handleSum = useCallback(() => {
    const newQtd = quantity + 1;
    setQuantity(newQtd);
  }, [quantity]);

  const handleMin = useCallback(() => {
    let newQtd = quantity;
    if (newQtd === 1) {
      return;
    }
    newQtd -= 1;
    setQuantity(newQtd);
  }, [quantity]);

  const handleQuantityInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setQuantity(Number(value));
    },
    []
  );

  return (
    <div className="button-container">
      <button id="button-rem" onClick={handleMin}>
        <FiMinus color="#FFF" size={20} />
      </button>

      <input
        ref={inputRef}
        min="1"
        max="999"
        value={quantity}
        onChange={handleQuantityInputChange}
        type="number"
      />

      <button id="button-adc" onClick={handleSum}>
        <FiPlus color="#FFF" size={20} />
      </button>
    </div>
  );
};

export default QtdButton;
