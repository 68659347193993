import React, { useCallback, useEffect, useState } from 'react';
import { BsExclamationCircle } from "react-icons/bs";
import { useParams } from 'react-router-dom';
import { useWorking } from '../../hooks/working';
import IUseParams from '../../interfaces/IUseParams';
import { IWorkingHour } from '../../interfaces/IWorkingHour';

import './styles.css';

export function Message() {
  const { company } = useParams<IUseParams>();
  const { getToday } = useWorking();
  const [today, setToday] = useState<IWorkingHour>();

  const handleToday = useCallback(async () => {
    const findDay = await getToday(company);
    if (findDay) {
      setToday(findDay);
    }
  }, [company, getToday]);

  useEffect(() => {    
    handleToday();    
    // eslint-disable-next-line
  }, [])

  if (!today?.message_today || today?.message_today.length === 0) {
    return null;
  }

  return (
    <div className="page-message">
      <BsExclamationCircle />
      <strong>{today?.message_today}</strong>
    </div>
  )
}