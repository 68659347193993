import React, { useCallback } from "react";

import "./styles.css";
import formatValue from "../../utils/formatValue";
import IProduct from "../../interfaces/IProduct";

import { useProduct } from "../../hooks/product";
import { useHistory, useParams } from "react-router-dom";
import IUseParams from "../../interfaces/IUseParams";

interface PropsProductItem {
  product: IProduct;
  page: string;
  // onClickProduct: () => void; 
}

const ProductItem: React.FC<PropsProductItem> = (props) => {
  const { include } = useProduct();
  const history = useHistory();
  const { company } = useParams<IUseParams>();

  const handleProduct = useCallback(() => {
    include(props.product);
    history.push(`/${company}/detail`)
  }, [props, include, company, history]);

  return (
    <div 
      className={
        `product-item-container${props.page === 'Home' ? ' product-item-container-collumn' : ''}`}
      onClick={handleProduct}
    >
 
      <div className="item-info">
        <h3 id="name">{props.product.name}</h3>
        <p id="category">{props.product.category.description}</p>
        <p id="description">{props.product.description}</p>
        <p id="price">{formatValue(props.product.price)}</p>
      </div>

      <div className="product-item-img">
        <img
          src={props.product.image_url}
          alt=""
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = require("../../assets/not-found-icon.jpg");
          }}
        />
      </div>
    </div>
  );
};

export default ProductItem;
