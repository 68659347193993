import React, { createContext, useCallback, useState, useContext } from "react";

import { IWorkingHour } from "../interfaces/IWorkingHour";
import api from "../services/api";
import { IOpeningClosing } from "../interfaces/IOpeningClosing";

interface WorkingContextData {
  isOpened: boolean;
  isOpen(open: boolean): void;
  getToday(company: string): Promise<IWorkingHour | void>;
  todayIsOpen(company: string): Promise<boolean>;
}

interface WorkingState {
  isOpened: boolean;
}

const WorkingContext = createContext<WorkingContextData>(
  {} as WorkingContextData
);

export const WorkingProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<WorkingState>(() => {
    return { isOpened: false } as WorkingState;
  });

  const isOpen = useCallback((open) => {
    setData({ isOpened: open });
  }, []);

  const getToday = useCallback(async (company) => {
    const response = await api.get<IWorkingHour>(`/${company}/workinghours/current`);
    const today = response.data;   

    return today;
  }, [])

  const todayIsOpen = useCallback(async (company) => {
    const response = await api.get<IOpeningClosing>(`/${company}/openingclosing`);

    const dayIsOpen = response.data;    
    return dayIsOpen.isOpen;
  }, []);

  return (
    <WorkingContext.Provider value={{ isOpened: data.isOpened, isOpen, getToday, todayIsOpen }}>
      {children}
    </WorkingContext.Provider>
  );
};

export function useWorking(): WorkingContextData {
  const context = useContext(WorkingContext);

  if (!context) {
    throw new Error("Working must be used within an ProductProvider");
  }

  return context;
}

export default WorkingContext;
