import React from 'react';

import { FiPlus, FiMinus } from "react-icons/fi";
import './styles.css';

interface Props {
  handleAdd: () => void;
  handleRemove: () => void;
  quantity: number;
}

const QtdButtonAds = ({ handleAdd, handleRemove, quantity }: Props) => {

  return (
    <div className="qtdbuttoncomp-container">

      <button
        className="qtdbuttoncomp-btn"
        id="button-addscomp-rem" onClick={handleRemove}>
        <FiMinus color="#FFF" size={20} />
      </button>

      <div className="qtdbuttoncomp-qtd">
        <h4>{quantity}</h4>
      </div>

      <button
        className="qtdbuttoncomp-btn"
        id="button-addscomp-adc" onClick={handleAdd}>
        <FiPlus color="#FFF" size={20} />
      </button>
    </div>
  );
}

export default QtdButtonAds;