import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageHeader from '../../components/PageHeader';
import { useConfig } from '../../hooks/config';
import IOrderMonitor from '../../interfaces/IOrderMonitor';
import IUseParams from '../../interfaces/IUseParams';
import api from '../../services/api';
import { BsClockFill, BsPhone } from "react-icons/bs";
import { FaCheck, FaHandHolding, FaMotorcycle, FaRegSmileWink, FaShoppingCart } from "react-icons/fa";

import './styles.css';
import formatValue from '../../utils/formatValue';
import { FiMapPin } from 'react-icons/fi';
import { useWorking } from '../../hooks/working';
import { IWorkingHour } from '../../interfaces/IWorkingHour';
import { clearInterval, setInterval } from 'timers';

const OrderMonitor: React.FC = () => {
  const { config, load } = useConfig();
  const { company, id } = useParams<IUseParams>();
  const [order, setOrder] = useState<IOrderMonitor>();
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [time, setTime] = useState('');
  const { getToday } = useWorking();
  const [today, setToday] = useState<IWorkingHour>();
  const [descStatus, setDescStatus] = useState('');
  const [counter, setCounter] = useState(60);

  const handleGetOrder = async () => {
    const response = await api.get(`${company}/orders/${id}`)
    setOrder(response.data);
  }

  const handleTotals = useCallback(() => {
    let finalSubtotal = 0;
    let finalTotal = 0;

    switch (order?.status) {
      case 'await': setDescStatus('Aguardando aprovação'); break;
      case 'approved': setDescStatus('Aprovado'); break;
      case 'preparing': setDescStatus('Em preparo...'); break;
      case 'out_for_delivery': setDescStatus(
        order.type_delivery === 0 ? 'Saiu para entrega' : 'Aguardando retirada'); break;
      case 'delivered': setDescStatus(order.type_delivery === 0 ? 'Pedido entregue' : 'Pedido retirado'); break;
    }

    //
    if (order?.order_products) {
      const { subtotal } = order?.order_products.reduce(
        (accumulator, item) => {
          accumulator.subtotal += Number(item.price * item.quantity);
          return accumulator;
        },
        {
          subtotal: 0
        }
      );
      finalSubtotal = subtotal;
    };
    finalTotal = Number(finalSubtotal) + Number(config.delivery_rate);
    setSubtotal(finalSubtotal);
    setTotal(finalTotal);
  }, [order, config.delivery_rate]);

  function handleStopwatch() {
    console.log(id);
    //
    if (order && today) {
      console.log('minutes_to_delivery', today.minutes_to_delivery);
      console.log('minutes_to_withdraw', today.minutes_to_withdraw);
      console.log('waiting_time', today.waiting_time);

      // Set the date we're counting down to
      // var countDownDate = new Date(order.created_at).getTime() + today.minutes_to_delivery * 60000;
      var countDownDate = new Date(order.created_at).getTime() +
        (order.type_delivery === 0 ?
          today.minutes_to_delivery_max :
          today.minutes_to_withdraw_max) * 60000;

      // Update the count down every 1 second
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // Display the result in the element with id="demo"
        setTime(hours + "h " + minutes + "m " + seconds + "s ");
        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          setTime('Tempo expirado!');
        }
      }, 1000);
    }
  }

  const handleToday = async () => {
    const currentDay = await getToday(company);

    if (currentDay) {
      setToday(currentDay);
    }

  }

  function handleStatus() {
    switch (order?.status) {
      case 'await': return 0;
      case 'approved': return 1;
      case 'preparing': return 2;
      case 'out_for_delivery': return 3;
      case 'delivered': return 4;
    }

    return 0;
  }

  useEffect(() => {    
    console.log(counter);
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (timer) return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (counter === 0){
      handleGetOrder();  
      setCounter(30);
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    handleTotals();
    handleStopwatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, today]);

  useEffect(() => {
    load(company);
    handleGetOrder();
    handleToday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  if (!order && !config) {
    return null;
  }

  return (
    <div className="ordermonitor">
      <PageHeader />
      <div className="ordermonitor-container">

        <div className="ordermonitor-title">
          <h1>Meu Pedido</h1>
        </div>

        <div className="ordermonitor-message">
          <h2>Olá {order?.name},</h2>
          <h3>Acompanhe como está seu pedido.</h3>
        </div>

        {handleStatus() <= 3 &&
          <div className="ordermonitor-timer">
            <h4>Estimativa de tempo de espera</h4>
            <h1>{time}</h1>
            <h4>Esse tempo é apenas uma referência!</h4>
          </div>}

        <div className="ordermonitor-status">

          <div className="ordermonitor-steps">

            <div className={`ordermonitor-step ${handleStatus() >= 1 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`}>
              <FaCheck size={20} />
            </div>

            <div className={`ordermonitor-row ${handleStatus() >= 2 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`} />

            <div className={`ordermonitor-step ${handleStatus() >= 2 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`}>
              <BsClockFill size={20} />
            </div>

            <div className={`ordermonitor-row ${handleStatus() >= 3 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`} />

            <div className={`ordermonitor-step ${handleStatus() >= 3 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`}>
              {order?.type_delivery === 0 ?
                <FaMotorcycle size={20} /> :
                <FaHandHolding />
              }
            </div>

            <div className={`ordermonitor-row ${handleStatus() >= 4 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`} />

            <div className={`ordermonitor-step ${handleStatus() >= 4 ? 'ordermonitor-step-active' : 'ordermonitor-step-inactive'}`}>
              <FaRegSmileWink size={20} />
            </div>
          </div>

          <h3>
            {descStatus}
          </h3>
        </div>

        <div className="ordermonitor-resume">

          <div className="ordermonitor-resume-row" />

          <div className="ordermonitor-resume-title">
            <FaShoppingCart />
            <h3>Resumo do pedido</h3>
          </div>

          <div className="ordermonitor-resume-items">
            {order?.order_products.map(item => {
              return (
                <div key={item.id} className="ordermonitor-resume-items-item">

                  <div className="ordermonitor-resume-items-item-main">
                    <div className="ordermonitor-item-sm">
                      <h4>{item.quantity} x</h4>
                    </div>
                    <div className="ordermonitor-item-lg">
                      <h4>{item.name}</h4>
                    </div>
                    <div className="ordermonitor-item-sm ordermonitor-item-right">
                      <h4>{formatValue(item.subtotal)}</h4>
                    </div>
                  </div>

                  <div className="ordermonitor-resume-items-item-additionals">
                    {item.additionals &&
                      item.additionals.map((additional) => (
                        <small key={additional.additional_id}>
                          {additional.type === "AD" ?
                            additional.quantity > 1 ?
                              `+ ${additional.quantity} X ${additional.description},` :
                              `+ ${additional.description},`
                            : `${additional.description}, `}
                        </small>
                      ))}
                    <small>{item.observ}</small>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="ordermonitor-resume-totals">
            <h4>Subtotal: {formatValue(subtotal)}</h4>
            <h4>Taxa entrega: {formatValue(config.delivery_rate)}</h4>
            <h4>Total: {formatValue(total)}</h4>
          </div>

          <div className="ordermonitor-resume-row" />

          <div className="ordermonitor-resume-delivery">
            <strong>
              <span>
                <BsPhone />
              </span>
              {order?.phone}
            </strong>

            <strong>
              <span>
                <FiMapPin />
              </span>
              {order?.type_delivery === 0 ?
                `${order?.address}, ${order?.number} - ${order?.neighborhood}` +
                `${order?.reference !== '' ? ` * order?.reference` : ''}` :
                `Retirada no local`
              }
              { }

            </strong>


          </div>

          <div className="ordermonitor-resume-row" />

          <small>Cód. Pedido: {order?.id}</small>
        </div>
      </div>
    </div>
  );
}

export default OrderMonitor;